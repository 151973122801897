import Link from 'next/link'
import Image from 'next/image'

import { SERVICES } from '@utils/constants'

export const Features = () => (
    <div className="bg-slate-50 py-10">
        <div className="mx-auto max-w-7xl ">
            <h2 className="t-h1">Servicii</h2>

            <div className="mt-10 px-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {SERVICES.map((feature) => (
                    <Link key={feature.name} href={feature.slug} passHref>
                        <a className="bg-white p-3 rounded-lg border-1 sm:hover:shadow-xl transition duration-300 ease-in-out transform sm:hover:-translate-y-1 sm:hover:scale-105">
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-100">
                                <Image
                                    src={feature.icon}
                                    width={24}
                                    height={24}
                                    alt={feature.name}
                                />
                            </div>
                            <h3 className="ml-16 text-lg leading-6 font-medium text-gray-900">
                                {feature.name}
                            </h3>

                            <p className="mt-2 ml-16 text-base text-gray-600 line-clamp-2">
                                {feature.description}
                            </p>
                        </a>
                    </Link>
                ))}
            </div>
        </div>
    </div>
)
export default Features
